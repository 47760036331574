define("discourse/plugins/discourse-solved/discourse/initializers/extend-for-solved-button", ["exports", "@ember/object", "discourse/lib/decorators", "discourse/lib/deprecated", "discourse/lib/icon-library", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse/models/topic", "discourse/models/user", "discourse/widgets/post-cooked", "discourse-i18n", "discourse/plugins/discourse-solved/discourse/components/solved-accept-answer-button", "discourse/plugins/discourse-solved/discourse/components/solved-unaccept-answer-button"], function (_exports, _object, _decorators, _deprecated, _iconLibrary, _pluginApi, _utilities, _topic, _user, _postCooked, _discourseI18n, _solvedAcceptAnswerButton, _solvedUnacceptAnswerButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeWithApi(api) {
    customizePostMenu(api);
    api.includePostAttributes("can_accept_answer", "can_unaccept_answer", "accepted_answer", "topic_accepted_answer");
    if (api.addDiscoveryQueryParam) {
      api.addDiscoveryQueryParam("solved", {
        replace: true,
        refreshModel: true
      });
    }
    api.decorateWidget("post-contents:after-cooked", dec => {
      if (dec.attrs.post_number === 1) {
        const postModel = dec.getModel();
        if (postModel) {
          const topic = postModel.topic;
          if (topic.accepted_answer) {
            const hasExcerpt = !!topic.accepted_answer.excerpt;
            const excerpt = hasExcerpt ? ` <blockquote> ${topic.accepted_answer.excerpt} </blockquote> ` : "";
            const solvedQuote = `
            <aside class='quote accepted-answer' data-post="${topic.get("accepted_answer").post_number}" data-topic="${topic.id}">
              <div class='title ${hasExcerpt ? "" : "title-only"}'>
                <div class="accepted-answer--solver-accepter">
                  <div class="accepted-answer--solver">
                    ${topic.solvedByHtml}
                  <\/div>
                  <div class="accepted-answer--accepter">
                    ${topic.accepterHtml}
                  <\/div>
                </div>
                <div class="quote-controls"><\/div>
              </div>
              ${excerpt}
            </aside>`;
            const cooked = new _postCooked.default({
              cooked: solvedQuote
            }, dec);
            return dec.rawHtml(cooked.init());
          }
        }
      }
    });
    api.attachWidgetAction("post", "acceptAnswer", function () {
      (0, _solvedAcceptAnswerButton.acceptAnswer)(this.model, this.appEvents, this.currentUser);
    });
    api.attachWidgetAction("post", "unacceptAnswer", function () {
      (0, _solvedUnacceptAnswerButton.unacceptAnswer)(this.model, this.appEvents);
    });
  }
  function customizePostMenu(api) {
    api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag,
        context: {
          post,
          firstButtonKey,
          secondLastHiddenButtonKey,
          lastHiddenButtonKey
        }
      } = _ref;
      let solvedButton;
      if (post.can_accept_answer) {
        solvedButton = _solvedAcceptAnswerButton.default;
      } else if (post.accepted_answer) {
        solvedButton = _solvedUnacceptAnswerButton.default;
      }
      solvedButton && dag.add("solved", solvedButton, post.topic_accepted_answer && !post.accepted_answer ? {
        before: lastHiddenButtonKey,
        after: secondLastHiddenButtonKey
      } : {
        before: ["assign",
        // button added by the assign plugin
        firstButtonKey]
      });
    });
  }
  var _default = _exports.default = {
    name: "extend-for-solved-button",
    initialize() {
      _topic.default.reopen({
        // keeping this here cause there is complex localization
        solvedByHtml: (0, _object.computed)("accepted_answer", "id", function () {
          const username = this.get("accepted_answer.username");
          const name = this.get("accepted_answer.name");
          const postNumber = this.get("accepted_answer.post_number");
          if (!username || !postNumber) {
            return "";
          }
          const displayedUser = this.siteSettings.display_name_on_posts && name ? name : (0, _utilities.formatUsername)(username);
          return (0, _discourseI18n.i18n)("solved.accepted_html", {
            icon: (0, _iconLibrary.iconHTML)("square-check", {
              class: "accepted"
            }),
            username_lower: username.toLowerCase(),
            username: displayedUser,
            post_path: `${this.url}/${postNumber}`,
            post_number: postNumber,
            user_path: _user.default.create({
              username
            }).path
          });
        }),
        accepterHtml: (0, _object.computed)("accepted_answer", function () {
          const username = this.get("accepted_answer.accepter_username");
          const name = this.get("accepted_answer.accepter_name");
          if (!this.siteSettings.show_who_marked_solved) {
            return "";
          }
          const formattedUsername = this.siteSettings.display_name_on_posts && name ? name : (0, _utilities.formatUsername)(username);
          return (0, _discourseI18n.i18n)("solved.marked_solved_by", {
            username: formattedUsername,
            username_lower: username.toLowerCase()
          });
        })
      });
      (0, _pluginApi.withPluginApi)("2.0.0", api => {
        (0, _deprecated.withSilencedDeprecations)("discourse.hbr-topic-list-overrides", () => {
          let topicStatusIcons;
          try {
            topicStatusIcons = require("discourse/helpers/topic-status-icons").default;
          } catch {}
          topicStatusIcons?.addObject(["has_accepted_answer", "far-square-check", "solved"]);
          api.modifyClass("raw-view:topic-status", Superclass => class extends Superclass {
            statuses() {
              const results = super.statuses;
              if (this.topic.has_accepted_answer || this.topic.accepted_answer) {
                results.push({
                  openTag: "span",
                  closeTag: "span",
                  title: (0, _discourseI18n.i18n)("topic_statuses.solved.help"),
                  icon: "far-square-check",
                  key: "solved"
                });
              } else if (this.topic.can_have_answer) {
                results.push({
                  openTag: "span",
                  closeTag: "span",
                  title: (0, _discourseI18n.i18n)("solved.has_no_accepted_answer"),
                  icon: "far-square"
                });
              }
              return results;
            }
            static #_ = (() => dt7948.n(this.prototype, "statuses", [(0, _decorators.default)("topic.{has_accepted_answer,accepted_answer,can_have_answer}")]))();
          });
        });
      });
      (0, _pluginApi.withPluginApi)("1.34.0", initializeWithApi);
      (0, _pluginApi.withPluginApi)("0.8.10", api => {
        api.replaceIcon("notification.solved.accepted_notification", "square-check");
      });
      (0, _pluginApi.withPluginApi)("0.11.0", api => {
        api.addAdvancedSearchOptions({
          statusOptions: [{
            name: (0, _discourseI18n.i18n)("search.advanced.statuses.solved"),
            value: "solved"
          }, {
            name: (0, _discourseI18n.i18n)("search.advanced.statuses.unsolved"),
            value: "unsolved"
          }]
        });
      });
      (0, _pluginApi.withPluginApi)("0.11.7", api => {
        api.addSearchSuggestion("status:solved");
        api.addSearchSuggestion("status:unsolved");
      });
    }
  };
});